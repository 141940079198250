<template>
  <v-container
    class="d-flex flex-column justify-center align-center"
    fill-height
  >
    <v-card class="pa-4">
      <div
        v-if="!$app.isProd"
        class="d-flex pa-3 title justify-center text-uppercase red--text"
      >
        <v-icon color="red">mdi-test-tube</v-icon>
        <div>{{ $app.env }}</div>
        <v-icon color="red">mdi-test-tube</v-icon>
      </div>
      <div class="d-flex justify-center mb-4">
        <img class="pt-1" :src="require('@/assets/logo.png')" height="40" />
      </div>
      <div>
        <div class="d-flex justify-center title" :style="{textAlign: 'center'}">
          {{ $t("message.registration1") }}
        </div>
        <div class="d-flex justify-center title" :style="{textAlign: 'center'}">
          {{ $t("message.registration2") }}
        </div>
        <div class="d-flex justify-center title" :style="{textAlign: 'center'}">
          {{ $t("message.registration3") }}
        </div>
      </div>
      <div class="d-flex justify-center mt-3">
        <v-btn
          color="primary"
          :disabled="false"
          class="setup-startup-register-button black--text ma-2"
          @click="openRegistration"
          >{{ $t("label.register") }}</v-btn
        >
      </div>
    </v-card>
    <v-bottom-sheet v-model="showRegisterDialog" persistent>
      <v-sheet class="text-center" min-height="450">
        <div
          v-if="!loading && step === 1"
          class="d-flex flex-column align-center"
        >
          <v-list max-width="800" class="pa-0">
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  class="setup-username-field title pa-2"
                  color="primary"
                  :id="userName"
                  :ref="userName"
                  :label="$t('label.username')"
                  :value="userName"
                  :dense="true"
                  :full-width="true"
                  :autofocus="true"
                  :rules="notEmptyRules"
                  @input="onChange"
                  @focus="selectField('userName')"
                ></v-text-field>
                <v-text-field
                  class="setup-password-field title pa-2"
                  color="primary"
                  :id="password"
                  :ref="password"
                  :label="$t('label.password')"
                  :value="password"
                  :dense="true"
                  :full-width="true"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  :rules="notEmptyRules"
                  @input="onChange"
                  @focus="selectField('password')"
                ></v-text-field>
                <div class="d-flex justify-center loginKeyboard">
                  <SimpleKeyboard
                    :key="componentKey"
                    @onChange="onChange"
                    @onKeyPress="onKeyPress"
                    :inputName="activeField"
                    :inputValue="activeValue"
                    classname="loginkeyboard"
                  />
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="dialogActions d-flex justify-end">
            <v-card-actions>
              <v-btn text @click="showRegisterDialog = false">{{
                $t("label.cancel")
              }}</v-btn>
              <v-btn
                :disabled="!userName || !password"
                class="setup-login-button title"
                color="primary"
                text
                @click="login"
                >{{ $t("label.login") }}</v-btn
              >
            </v-card-actions>
          </div>
        </div>
        <div
          v-if="!loading && step === 2"
          class="d-flex flex-column align-center"
        >
          <v-list max-width="800" class="pa-0">
            <v-list-item>
              <v-list-item-content>
                <v-select
                  ref="tenantId"
                  :items="adminForTenants"
                  :label="$t('label.tenantId')"
                  :dense="true"
                  class="title pa-2"
                  :rules="notEmptyRules"
                  :full-width="true"
                  v-model="cashierDevice.tenantId"
                />
                <v-text-field
                  class="setup-device-name-field title pa-2"
                  color="primary"
                  id="cashierDeviceName"
                  ref="cashierDeviceName"
                  :label="$t('label.deviceName')"
                  :value="cashierDevice.name"
                  :dense="true"
                  :full-width="true"
                  :autofocus="true"
                  :rules="notEmptyRules"
                  @input="onChange"
                  @focus="selectField('cashierDeviceName')"
                ></v-text-field>
                <div class="d-flex justify-center registerKeyboard">
                  <SimpleKeyboard
                    :key="componentKey"
                    @onChange="onChange"
                    @onKeyPress="onKeyPress"
                    :inputName="activeField"
                    :inputValue="activeValue"
                    classname="registerkeyboard"
                  />
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div class="dialogActions d-flex justify-end">
            <v-card-actions>
              <v-btn text @click="openRegistration">{{
                $t("label.cancel")
              }}</v-btn>
              <v-btn
                :disabled="!cashierDevice.tenantId || !cashierDevice.name"
                class="setup-form-register-button title"
                color="primary"
                text
                @click="handleRegisterCashier"
                >{{ $t("label.register") }}</v-btn
              >
            </v-card-actions>
          </div>
        </div>
        <div v-if="loading" class="d-flex justify-center pt-9">
          <v-progress-circular
            :size="150"
            :width="2"
            indeterminate
            color="primary"
            class="mt-9"
          ></v-progress-circular>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import SimpleKeyboard from "@/components/Utils/SimpleKeyboard.vue";
import jwt_decode from "jwt-decode";

export default {
  name: "setup",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      step: 1,
      cashierDevice: undefined,
      loading: false,
      showRegisterDialog: false,
      userName: "",
      password: "",
      adminForTenants: [],
      activeField: "",
      activeValue: "",
      notEmptyRules: [
        (value) =>
          (!!value && value.trim().length > 0) || this.$t("message.required"),
      ],
      showPassword: false,
      componentKey: 0,
    };
  },
  methods: {
    forceKeyboardsRerender() {
      this.componentKey += 1;
    },
    openRegistration() {
      this.step = 1;
      this.cashierDevice = {
        id: this.$estore.get("deviceId"),
        type: "cashier",
        model: "software",
        name: null,
        tenantId: null,
      };
      this.loading = false;
      this.userName = "";
      this.password = "";
      this.adminForTenants = [];
      this.$security.access_token = undefined;
      this.showRegisterDialog = true;
      this.forceKeyboardsRerender();
    },
    async login() {
      if (this.userName && this.password) {
        this.loading = true;
        const accessToken = await this.$security.getAccessToken(
          this.userName,
          this.password
        );
        if (!accessToken) {
          this.openRegistration();
        } else {
          const decoded = jwt_decode(accessToken);
          this.adminForTenants = decoded["admin-for-tenants"];
          if (!this.adminForTenants || this.adminForTenants.length === 0)
            this.openRegistration();
          this.cashierDevice.tenantId = this.adminForTenants[0];
          this.loading = false;
          this.step = 2;
        }
      }
    },
    async handleRegisterCashier() {
      this.loading = true;
      //make sure that the token is valid, or refresh with correct credentials
      await this.$security.getAccessToken(this.userName, this.password);
      let storedDeviceId = this.$estore.get("deviceId");
      if (storedDeviceId === "undefined") {
        storedDeviceId = null;
      }
      let url = this.$app.urls.softwareRegistration
        .replace("{tenantId}", this.cashierDevice.tenantId)
        .replace("{deviceId}", storedDeviceId || this.$uuid.v4());
      this.$http({
        url: url,
        method: "put",
        data: this.cashierDevice,
      })
        .then((response) => {
          this.loading = false;
          this.openCashier(response.data);
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    openCashier(data) {
      this.step = 3;
      this.$estore.set("deviceId", data.deviceId);
      this.$estore.set("devicePassword", data.encryptedPassword);
      this.$estore.set("tenant.tenantId", this.cashierDevice.tenantId);
      this.$security.access_token = undefined;
      this.$router.push("/");
    },
    selectField(fieldName) {
      this.activeField = fieldName;
      // TODO: generalize instead of specific check
      this.activeValue =
        fieldName === "cashierDeviceName"
          ? this.cashierDevice.name
          : this[this.activeField];
    },
    onChange(input) {
      this.activeValue = input;
      // TODO: generalize instead of specific check
      if (this.activeField === "cashierDeviceName") {
        this.cashierDevice.name = input;
      } else {
        this[this.activeField] = input;
      }
    },
    onKeyPress(button) {
      if (button === "{ent}") {
        this.login();
      }
    },
    closeApp() {
      // ipcRenderer.send("closed");
    },
  },
  mounted() {
    // ipcRenderer.on("app-close", this.closeApp);
  },
  beforeDestroy() {
    // ipcRenderer.removeListener("app-close", this.closeApp);
  },
};
</script>

<style>
.dialogActions {
  width: 800px;
}

.loginkeyboard,
.registerkeyboard {
  max-width: 755px !important;
  background-color: #424242 !important;
}

.loginkeyboard > .hg-row > .hg-standardBtn,
.registerkeyboard > .hg-row > .hg-standardBtn,
.loginkeyboard > .hg-row > .hg-button-abc,
.registerkeyboard > .hg-row > .hg-button-abc,
.loginkeyboard > .hg-row > .hg-button-ent,
.registerkeyboard > .hg-row > .hg-button-ent,
.loginkeyboard > .hg-row > .hg-button-sym,
.registerkeyboard > .hg-row > .hg-button-sym,
.loginkeyboard > .hg-row > .hg-button-numbers,
.registerkeyboard > .hg-row > .hg-button-numbers,
.loginkeyboard > .hg-row > .hg-button-shift,
.registerkeyboard > .hg-row > .hg-button-shift,
.loginkeyboard > .hg-row > .hg-button-space,
.registerkeyboard > .hg-row > .hg-button-space,
.loginkeyboard > .hg-row > .hg-functionBtn,
.registerkeyboard > .hg-row > .hg-functionBtn,
.loginkeyboard > .hg-row > .hg-button-backspace,
.registerkeyboard > .hg-row > .hg-button-backspace {
  min-width: 70px !important;
  max-width: 70px !important;
  width: 70px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  height: 60px !important;
  color: #424242 !important;
  font-size: 1.7em !important;
}

.loginkeyboard > .hg-row > .hg-functionBtn,
.registerkeyboard > .hg-row > .hg-functionBtn {
  color: #424242 !important;
  font-size: 1.7em !important;
}

.loginkeyboard > .hg-row > .hg-button-backspace,
.registerkeyboard > .hg-row > .hg-button-backspace {
  min-width: 220px !important;
  max-width: 220px !important;
  width: 220px !important;
}

.loginkeyboard > .hg-row > .hg-button-sym,
.registerkeyboard > .hg-row > .hg-button-sym {
  min-width: 145px !important;
  max-width: 145px !important;
  width: 145px !important;
}
.loginkeyboard > .hg-row > .hg-button-numbers,
.registerkeyboard > .hg-row > .hg-button-numbers,
.loginkeyboard > .hg-row > .hg-button-abc,
.registerkeyboard > .hg-row > .hg-button-abc {
  min-width: 145px !important;
  max-width: 145px !important;
  width: 145px !important;
}

.loginkeyboard > .hg-row > .hg-button-space,
.registerkeyboard > .hg-row > .hg-button-space {
  min-width: 445px !important;
  max-width: 445px !important;
  width: 445px !important;
}

.loginkeyboard > .hg-row > .hg-button-ent,
.registerkeyboard > .hg-row > .hg-button-ent {
  min-width: 145px !important;
  max-width: 145px !important;
  width: 145px !important;
}

.v-dialog {
  max-height: unset !important;
}
</style>
